<template>
    <div class="testReport_pages9">
        <Head :UserInfo="UserInfo"></Head>
        <div class="testReport_pages9_content">
            <div class="testReport_pages9_Num">0{{ curentIndex + 5 }}</div>
            <div class="testReport_pages9_title">食疗建议</div>
            <div class="testReport_pages9_line"></div>
        </div>
        <div class="content9">
            <div class="content9_title">
                {{ Pages9Data.FoodName }}
            </div>
            <div class="content9_img">
                <img class="img" :src="Pages9Data.FoodPicture" alt="">
            </div>
            <div>
                <div class="content9_contnet">
                    <div class="content9_contnet1">
                        <div class="content9_contnet1_title">材料</div>
                        <div class="content9_contnet1_line"></div>

                    </div>
                    <div class="content9_contnet2">{{ Pages9Data.FoodMaterial }}</div>
                </div>
            </div>
            <div>
                <div class="content9_contnet">
                    <div class="content9_contnet1">
                        <div class="content9_contnet1_title">做法</div>
                        <div class="content9_contnet1_line"></div>

                    </div>
                    <div class="content9_contnet2">{{ Pages9Data.FoodMethod }}</div>
                </div>
            </div>
            <div>
                <div class="content9_contnet">
                    <div class="content9_contnet1">
                        <div class="content9_contnet1_title">注意事项</div>
                        <div class="content9_contnet1_line"></div>

                    </div>
                    <div class="content9_contnet2">{{ Pages9Data.FoodAttention }}</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Head from "./head.vue"
export default {
    components: {
      Head,
    },
    props: ['Pages9Data', 'curentIndex','UserInfo'],
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.testReport_pages9 {
    width: 595px;
    height: 841px;
    padding: 0 60px 0 53px;
    position: relative;
    overflow: hidden;
    .testReport_pages9_content {
        .testReport_pages9_Num {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
        }

        .testReport_pages9_title {
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #4A9AAF;
        }

        .testReport_pages9_line {
            width: 37px;
            height: 1px;
            border: 1px solid #4A9AAF;
            margin-top: 17px;
        }
    }
}

.content9 {
    .content9_title {
        width: 141px;
        height: 31px;
        background: #D0E7EF;
        border-radius: 0px 15px 15px 0px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #4A9AAF;
        text-align: center;
        line-height: 31px;
        margin-top: 10px;
    }

    .content9_img {
      overflow: hidden;
      border-radius: 30px;
        width:243px;
        height: 243px;
        margin: 0 auto;
        margin-top: 20px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.content9_contnet {
  .content9_contnet2{
    margin-top: 5px;
  }
    .content9_contnet1 {
        margin-top: 20px;

        .content9_contnet1_title {
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #4A9AAF;
        }

        .content9_contnet1_line {
            width: 31px;
            height: 1px;
            border: 1px solid #4A9AAF;
        }
    }
}
</style>
