<template>
  <div v-if="PagesInitData" class="testReport_pages4 A4height">
    <Head :UserInfo="PagesInitData.UserInfo"></Head>
    <div class="testReport_pages4_title_box">
      <div class="testReport_pages4_title_box_serialNumber">
        01
      </div>
      <div>
        <div class="testReport_pages4_title_box_title" >
         {{ PagesInitData.Plate2.Title }}
      </div>
      <div class="testReport_pages4_title_box_title_h">

      </div>
      </div>

    </div>
    <div style="width: 100%;">
      <div v-if="objectData" class="HealthAnalysisMatrix_info">
        <div class="HealthAnalysisMatrix_info_left">
          <!-- <div v-if="objectData.rightList.length == 1"  class="HealthAnalysisMatrix_info_right_title_box"
            style="justify-content: center;opacity:0;">
            <div v-for="(item, index) in objectData.rightList" :key="index" :style="'color:' + item.color"
              class="HealthAnalysisMatrix_info_right_title">
              <div class="HealthAnalysisMatrix_info_right_title_li">
                {{ item.title }}
              </div>
              <div v-if="item.leve" class="HealthAnalysisMatrix_info_right_title_leve">
                {{ item.leve }}
              </div>
            </div>
          </div> -->
          <div v-if="objectData.rightList.length > 1" style="opacity:0;" class="HealthAnalysisMatrix_info_right_title_box">
            <div v-for="(item, index) in objectData.rightList" :key="index" :style="'color:' + item.color"
              class="HealthAnalysisMatrix_info_right_title">
              <div class="HealthAnalysisMatrix_info_right_title_li">
                {{ item.title }}
              </div>
              <div v-if="item.leve" class="HealthAnalysisMatrix_info_right_title_leve">
                {{ item.leve }}
              </div>
            </div>
          </div>
          <div v-for="(item, index) in objectData.leftList" :key="index"
            :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1)"
            class="HealthAnalysisMatrix_info_left_li a_flex">
            <div>{{ item.title }}</div>&nbsp;&nbsp;
            <div :style="'color:' + item.color + ';fontSize:17px;marginTop:2px'">{{ item.FunctionLevel }}</div>

          </div>
        </div>
        <div class="HealthAnalysisMatrix_info_center">
          <div class="HealthAnalysisMatrix_info_center_box">
            <div class="HealthAnalysisMatrix_info_center_box_img a_flex">
              <img class="wh100"
                src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/9F5BF718781C42C8A24D63FD871C2702.png"/>
            </div>
            <div class="HealthAnalysisMatrix_info_center_box_left_center">
              <div v-if="objectData.rightList.length > 1" v-for="(item, index) in objectData.rightList" :key="index"
                class="HealthAnalysisMatrix_info_center_box_left_center_radio a_flex">
                <div v-if="index == 0" style="display: flex;flex-direction: column;border-radius: 50%;"
                  class="wh100 a_flex HealthAnalysisMatrix_info_center_box_left_center_radio_box"
                  :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1) + ';'">
                  <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_leve">
                    {{ item.leve }}
                  </div>
                  <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_title">
                    {{ item.title }}
                  </div>
                </div>
                <div v-if="index == 1" style="display: flex;flex-direction: column;border-radius: 50%;"
                  class="wh100 a_flex HealthAnalysisMatrix_info_center_box_left_center_radio_box"
                  :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1) + ';'">
                  <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_title">
                    {{ item.title }}
                  </div>
                  <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_leve">
                    {{ item.leve }}
                  </div>
                </div>
              </div>
              <div v-if="objectData.rightList.length == 1" v-for="(item, index) in objectData.rightList" :key="index"
                class="HealthAnalysisMatrix_info_center_box_left_center_radio a_flex">
                <div v-if="index == 0" style="display: flex;flex-direction: column;border-radius: 50%;"
                  class="wh100 a_flex HealthAnalysisMatrix_info_center_box_left_center_radio_box"
                  :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1) + ';'">
                  <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_title">
                    {{ item.title }}
                  </div>
                  <div class="HealthAnalysisMatrix_info_center_box_left_center_radio_box_leve">
                    {{ item.leve }}
                  </div>
                </div>
              </div>
            </div>
            <div class="HealthAnalysisMatrix_info_center_box_img a_flex">
              <img class="wh100"
                src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/7B376D9C9F7E4615A5CC2CB02039A0E9.png"/>
            </div>
          </div>
        </div>
        <div class="HealthAnalysisMatrix_info_right">
          <div v-if="objectData.rightList.length == 1" class="HealthAnalysisMatrix_info_right_title_box"
            style="justify-content: center;">
            <div v-for="(item, index) in objectData.rightList" :key="index" :style="'color:' + item.color"
              class="HealthAnalysisMatrix_info_right_title">
              <div class="HealthAnalysisMatrix_info_right_title_li">
                {{ item.title }}
              </div>
              <div v-if="item.leve" class="HealthAnalysisMatrix_info_right_title_leve">
                {{ item.leve }}
              </div>
            </div>
          </div>
          <div v-if="objectData.rightList.length > 1" class="HealthAnalysisMatrix_info_right_title_box">
            <div v-for="(item, index) in objectData.rightList" :key="index" :style="'color:' + item.color"
              class="HealthAnalysisMatrix_info_right_title">
              <div class="HealthAnalysisMatrix_info_right_title_li">
                {{ item.title }}
              </div>
              <div v-if="item.leve" class="HealthAnalysisMatrix_info_right_title_leve">
                {{ item.leve }}
              </div>
            </div>
          </div>
          <div class="HealthAnalysisMatrix_info_right_content">
            <div v-for="(item, index) in objectData.rightListA"
              :style="item.type == 3 ? 'width:100%;' : item.type == 1 ? 'justify-content: flex-start;margin-right:18px;' : 'justify-content: flex-end;float:right;'"
              class="HealthAnalysisMatrix_info_right_content_li a_flex">
              <div v-if="item.type == 3" class="HealthAnalysisMatrix_info_right_content_liA"
                :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1) + ';width:100%;'">
                <div class="HealthAnalysisMatrix_info_right_content_liA_title">
                  {{ item.name }}
                </div>
                <div class="HealthAnalysisMatrix_info_right_content_liA_title_leve">
                  {{ item.leve }}
                </div>
              </div>
              <div v-if="item.type != 3" class="HealthAnalysisMatrix_info_right_content_liA"
                :style="'color:' + item.color + ';border:1px solid ' + item.color + ';background:' + hexToRgb(item.color, 0.1) + ';width:120px;'">
                <div class="HealthAnalysisMatrix_info_right_content_liA_title">
                  {{ item.name }}
                </div>
                <div class="HealthAnalysisMatrix_info_right_content_liA_title_leve">
                  {{ item.leve }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="HealthAnalysisMatrix_bottom">
        <div class="HealthAnalysisMatrix_bottom_title">
          {{ objectData.content.title }}
        </div>
        <div v-for="(item, index) in objectData.content.list" :key="index" class="HealthAnalysisMatrix_bottom_list">
          <span v-if="item.leve" class="HealthAnalysisMatrix_bottom_list_leve a_flex"
            :style="'border:1px solid ' + item.color + ';color:' + item.color + ';background:' + hexToRgb(item.color, 0.1)">{{ item.leve }}</span>
          <span class="HealthAnalysisMatrix_bottom_list_title" :style="'color:' + item.color">{{ item.title }}:</span>
          <span class="HealthAnalysisMatrix_bottom_list_text">{{ item.text }}</span>
        </div>
      </div>
      <div class="pages4_bottom">
        <div class="pages4_bottom_li">
          <div class="pages4_bottom_li_left a_flex" style='color:#10854A;border:1px solid #10854A;'
            :style="'background:' + hexToRgb('#10854A', 0.1)">
            A
          </div>
          <div class="pages4_bottom_li_right" style='color:#10854A;'>
            最佳
          </div>
        </div>

        <div class="pages4_bottom_li">
          <div class="pages4_bottom_li_left a_flex" style='color:#5EC692;border:1px solid #5EC692;'
            :style="'background:' + hexToRgb('#5EC692', 0.1)">
            B
          </div>
          <div class="pages4_bottom_li_right" style='color:#5EC692;'>
            正常
          </div>
        </div>

        <div class="pages4_bottom_li">
          <div class="pages4_bottom_li_left a_flex" style='color:#EEC122;border:1px solid #EEC122;'
            :style="'background:' + hexToRgb('#EEC122', 0.1)">
            C
          </div>
          <div class="pages4_bottom_li_right" style='color:#EEC122;'>
            风险
          </div>
        </div>

        <div class="pages4_bottom_li">
          <div class="pages4_bottom_li_left a_flex" style='color:#D51931;border:1px solid #D51931;'
            :style="'background:' + hexToRgb('#D51931', 0.1)">
            D
          </div>
          <div class="pages4_bottom_li_right" style='color:#D51931;'>
            疾病
          </div>
        </div> -->

      </div>
    </div>
  </div>
</template>

<script>
import Head from './head.vue'
export default {
  props: {
    PagesInitData: {
      required: true,
      default: ''
    }
  },
  watch: {
    PagesInitData: {
    	deep: true, // 开启深度监听 专门对付复杂数据类型
    	handler: function (newValueA, oldValue) {
        console.log(newValueA.UserInfo, 'newValueA')
        const newValue = newValueA.Plate2
        const leftList = []
        for (let i = 0; i < newValue.LifeList.length; i++) {
          const a = {
            title: newValue.LifeList[i].Title,
            color: newValue.LifeList[i].Color,
            FunctionLevel: newValue.LifeList[i].ColorLevel
          }
          leftList.push(a)
        }
    		this.objectDataA.leftList = leftList

        const content = {
          title: newValue.HeathDescribe,
          list: []
        }
        for (let i = 0; i < newValue.DescribeList.length; i++) {
          const a = {
            leve: newValue.DescribeList[i].FunctionLevel,
            title: newValue.DescribeList[i].FunctionName,
            text: newValue.DescribeList[i].Introduce,
            color: newValue.DescribeList[i].FunctionColor
          }
          content.list.push(a)
        }
        this.objectDataA.content = content

        const rightList = []
        const rightList1 = {
          title: newValue.FunctionDomain1.FunctionName,
          leve: newValue.FunctionDomain1.FunctionLevel,
          color: newValue.FunctionDomain1.FunctionColor,
          list: []
        }
        const rightList2 = {
          title: newValue.FunctionDomain2.FunctionName,
          leve: newValue.FunctionDomain2.FunctionLevel,
          color: newValue.FunctionDomain2.FunctionColor,
          list: []
        }
        if (newValue.FunctionDomain1.FactorFunctionList) {
          for (let i = 0; i < newValue.FunctionDomain1.FactorFunctionList.length; i++) {
            const a = {
              name: newValue.FunctionDomain1.FactorFunctionList[i].FunctionName,
              color: newValue.FunctionDomain1.FactorFunctionList[i].FunctionColor,
              leve: newValue.FunctionDomain1.FactorFunctionList[i].FunctionLevel
            }
            rightList1.list.push(a)
          }
          rightList.push(rightList1)
        }
        if (newValue.FunctionDomain2.FactorFunctionList) {
          for (let i = 0; i < newValue.FunctionDomain2.FactorFunctionList.length; i++) {
            const a = {
              name: newValue.FunctionDomain2.FactorFunctionList[i].FunctionName,
              color: newValue.FunctionDomain2.FactorFunctionList[i].FunctionColor,
              leve: newValue.FunctionDomain2.FactorFunctionList[i].FunctionLevel
            }
            rightList2.list.push(a)
          }
          rightList.push(rightList2)
        }
        // console.log('rightList111',rightList)
        this.objectDataA.rightList = rightList

        console.log('this.objectData11', this.objectData)
    		// this.objectDataA.rightList=[this.data.FunctionDomain1,this.data.FunctionDomain2]
    		// this.objectDataA.content.title=this.data.HeathDescribe
    		// this.objectDataA.content.list=this.data.DescribeList
    		this.init()
    	}
    }
  },
  data () {
    return {
      ifZk: false,
      objectData: '',
      obj1: '',
      objectDataA: {
        // content: {
        //   title: '影响您健康隐患的相关失衡因素解析',
        //   list: [{
        //       leve: 'D',
        //       title: '胰岛素抵抗',
        //       text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
        //       color: '#D51931'
        //     },
        //     {
        //       leve: 'D',
        //       title: '胰岛素抵抗',
        //       text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
        //       color: '#D51931'
        //     },
        //     {
        //       title: '胰岛素抵抗',
        //       text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
        //       color: '#F9C716'
        //     },
        //     {
        //       title: '胰岛素抵抗',
        //       text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
        //       color: '#F9C716'
        //     }
        //   ]
        // },
        // leftList: [{
        //     title: '饮食',
        //     color: '#F9C716',
        //   },
        //   {
        //     title: '压力',
        //     color: '#5EC692',
        //   },
        //   {
        //     title: '睡眠',
        //     color: '#5EC692',
        //   },
        //   {
        //     title: '运动',
        //     color: '#F9C716',
        //   },
        // ],
        // rightList: [{
        //     title: '脂肪肝',
        //     leve: 'D',
        //     color: '#D51931',
        //     list: [{
        //         name: '甲状腺',
        //         color: '#D51931',
        //         leve: 'D',
        //       },
        //       {
        //         name: '慢性肝炎',
        //         color: '#F9C716',
        //         leve: 'C',
        //       },
        //     ]
        //   },
        //   {
        //     title: '脂肪肝',
        //     leve: 'D',
        //     color: '#D51931',
        //     list: [{
        //         name: '甲状腺',
        //         color: '#D51931',
        //         leve: 'D',
        //       },
        //       {
        //         name: '肝炎',
        //         color: '#F9C716',
        //         leve: 'C',
        //       },
        //     ]
        //   }
        // ]
      }
    }
  },
  methods: {
    init () {
      	const newArr = []
      // console.log('this.objectDataA.rightList',this.objectDataA.rightList)
      	if (this.objectDataA.rightList.length == 2) {
      	    for (let i = 0; i < this.objectDataA.rightList[0].list.length; i++) {
      	        let ifT = false
      	        for (let q = 0; q < this.objectDataA.rightList[1].list.length; q++) {
      	            if (this.objectDataA.rightList[1].list[q].name == this.objectDataA.rightList[0].list[i].name) {
      	                // console.log('this.objectDataA.rightList[1].list[q]',this.objectDataA.rightList[1].list[q])
      	                ifT = true
      	            }
      	        }
      	        if (ifT) {
      	            const a = this.objectDataA.rightList[0].list[i]
      	            a.type = 3
      	            a.index = 3
      	            newArr.push(a)
      	        } else {
      	            const a = this.objectDataA.rightList[0].list[i]
      	            a.type = 1
            a.index = 2
      	            newArr.push(a)
      	        }
      	    }

      	    for (let i = 0; i < this.objectDataA.rightList[1].list.length; i++) {
      	        let ifT = false
      	        for (let q = 0; q < newArr.length; q++) {
      	            if (this.objectDataA.rightList[1].list[i].name == newArr[q].name) {
      	                ifT = true
      	            }
      	        }
      	        if (!ifT) {
      	            const a = this.objectDataA.rightList[1].list[i]
      	            a.type = 2
            a.index = 1
      	            newArr.push(a)
      	        }
      	    }
      	} else if (this.objectDataA.rightList.length == 1) {
      	    for (let i = 0; i < this.objectDataA.rightList[0].list.length; i++) {
      	        const a = this.objectDataA.rightList[0].list[i]
      	        a.type = 3
          a.index = 3
      	        newArr.push(a)
      	    }
      	}
      // console.log('newArr1111111',newArr)
      const newArrA = []
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i].type == 3) {
          newArrA.push(newArr[i])
        }
      }
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i].type == 1) {
          newArrA.push(newArr[i])
        }
      }
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i].type == 2) {
          newArrA.push(newArr[i])
        }
      }
      const newArrlist = newArrA

      // console.log('newArr22222',newArr)
      //                     console.log('newArr',newArr)
      //                     let newArrlist=[];
      //                     let ifTypeLength=newArr.length-1;
      //                     let ifType=false;
      //                     for(let i=0;i<newArr.length;i++){
      //                         if(newArrlist.length<newArr.length){
      //                             if(newArr[i].type==3){
      //                                 newArrlist.push(newArr[i]);
      //                             }else{
      //                                if(!ifType){
      //                                    ifType=true;
      //                                    newArrlist.push(newArr[i]);
      //                                }else{
      //                                    ifType=false;
      //                                    newArrlist.push(newArr[ifTypeLength]);
      //                                    ifTypeLength--
      //                                    i--
      //                                }
      //                             }
      //                         }
      //                     }
      // for(let i=0;i<newArrlist.length;i++){
      //     for(let q=i+1;q<newArrlist.length;q++){
      //         if(newArrlist[i].index<newArrlist[q].index){
      //             let newA=newArrlist[q];
      //             newArrlist[q]=newArrlist[i];
      //             newArrlist[i]=newA;
      //         }
      //     }
      // }
      	// console.log('newArrlist',newArrlist)
      	this.objectDataA.rightListA = newArrlist
      	this.objectData = this.objectDataA
      console.log('this.objectData', this.objectData)
    },
    hexToRgb (hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  },
  mounted () {
    return
    const newArr = []
    if (this.objectDataA.rightList.length > 1) {
      for (let i = 0; i < this.objectDataA.rightList[0].list.length; i++) {
        let ifT = false
        for (let q = 0; q < this.objectDataA.rightList[1].list.length; q++) {
          if (this.objectDataA.rightList[1].list[q].name == this.objectDataA.rightList[0].list[i].name) {
            console.log('this.objectDataA.rightList[1].list[q]', this.objectDataA.rightList[1].list[q])
            ifT = true
          }
        }
        if (ifT) {
          const a = this.objectDataA.rightList[0].list[i]
          a.type = 3
          newArr.push(a)
        } else {
          const a = this.objectDataA.rightList[0].list[i]
          a.type = 1
          newArr.push(a)
        }
      }
      for (let i = 0; i < this.objectDataA.rightList[1].list.length; i++) {
        let ifT = false
        for (let q = 0; q < newArr.length; q++) {
          if (this.objectDataA.rightList[1].list[i].name == newArr[q].name) {
            ifT = true
          }
        }
        if (!ifT) {
          const a = this.objectDataA.rightList[1].list[i]
          a.type = 2
          newArr.push(a)
        }
      }
    } else {
      for (let i = 0; i < this.objectDataA.rightList[0].list.length; i++) {
        const a = this.objectDataA.rightList[0].list[i]
        a.type = 3
        newArr.push(a)
      }
    }
    console.log('newArr', newArr)
    for (let i = 0; i < newArr.length; i++) {
      for (let q = i + 1; q < newArr.length; q++) {
        if (newArr[i].type < newArr[q].type) {
          let a
          a = newArr[q]
          newArr[q] = newArr[i]
          newArr[i] = a
        }
      }
    }
    console.log('newArr', newArr)
    const newArrlist = []
    let ifTypeLength = newArr.length - 1
    let ifType = false
    for (let i = 0; i < newArr.length; i++) {
      if (newArrlist.length < newArr.length) {
        if (newArr[i].type == 3) {
          newArrlist.push(newArr[i])
        } else {
          if (!ifType) {
            ifType = true
            newArrlist.push(newArr[i])
          } else {
            ifType = false
            newArrlist.push(newArr[ifTypeLength])
            ifTypeLength--
            i--
          }
        }
      }
    }

    this.objectDataA.rightListA = newArrlist
    this.objectData = this.objectDataA
  },
  components: {
    Head
  }
}
</script>

<style lang="scss" scoped>
  .testReport_pages4 {
    background-color: #fff;
    width: 595px;
    height: 841px;
    overflow: hidden;
    .testReport_pages4_title_box {
      .testReport_pages4_title_box_serialNumber {
        padding-left: 73px;
        font-size: 55px;
        font-weight: 400;
        color: #DFE3E8;
      }

      .testReport_pages4_title_box_title {
        padding-left: 73px;
        font-size: 21px;
        font-weight: 500;
        color: #4A9AAF;
      }

      .testReport_pages4_title_box_title_h {
        margin-top: 18px;
        width: 37px;
        height: 1px;
        margin-left: 72px;
        background-color: #4A9AAF;
      }
    }
  }

  .HealthAnalysisMatrix_info {
    width: 100%;
    padding: 0 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // margin-top: 10px;

    .HealthAnalysisMatrix_info_left {
      display: flex;
      flex-direction: column;

      .HealthAnalysisMatrix_info_left_li {
        margin-bottom: 13px;
        box-sizing: border-box;
        // width: 80px;
        width: 140px;
        height: 32px;
        border-radius: 6px;
        display: flex;
        align-items: center;
      }
    }

    .HealthAnalysisMatrix_info_center {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin-top: 25px;

      .HealthAnalysisMatrix_info_center_box {
        position: relative;
        top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .HealthAnalysisMatrix_info_center_box_img {
          width: 13px;
          height: 25px;
        }

        .HealthAnalysisMatrix_info_center_box_left_center {
          display: flex;
          flex-direction: column;

          .HealthAnalysisMatrix_info_center_box_left_center_radio {
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            width: 105px;
            height: 105px;

            .HealthAnalysisMatrix_info_center_box_left_center_radio_box {
              .HealthAnalysisMatrix_info_center_box_left_center_radio_box_title {
                font-size: 16px;
              }

              .HealthAnalysisMatrix_info_center_box_left_center_radio_box_leve {
                font-size: 23px;
              }
            }
          }

          .HealthAnalysisMatrix_info_center_box_left_center_radio:nth-of-type(1) {
            position: relative;
            top: 7px;
          }

          .HealthAnalysisMatrix_info_center_box_left_center_radio:nth-of-type(2) {
            position: relative;
            top: -7px;
          }
        }
      }
    }

    .HealthAnalysisMatrix_info_right {
      width: 140px;

      .HealthAnalysisMatrix_info_right_content {

        // display: flex;
        // flex-direction: column;
        .HealthAnalysisMatrix_info_right_content_li {
          display: flex;
          width: 140px;
          box-sizing: border-box;
          padding-bottom: 10px;
          float: left;
          width: auto;

          .HealthAnalysisMatrix_info_right_content_liA {
            min-height: 33px;
            border-radius: 6px;
            display: flex;
            // flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 140px;
            .HealthAnalysisMatrix_info_right_content_liA_title {
              font-size: 13px;
              font-weight: 500;
            }

            .HealthAnalysisMatrix_info_right_content_liA_title_leve {
              position: relative;
              top: 2px;
              margin-left: 5px;
              font-size: 17px;
            }
          }
        }
      }
    }

  }

  .HealthAnalysisMatrix_bottom {
    margin-top: 18px;
    padding: 0 70px;

    .HealthAnalysisMatrix_bottom_title {
      margin-bottom: 23px;
      font-size: 14px;
      color: #4A9AAF;
    }

    .HealthAnalysisMatrix_bottom_list {
      font-size: 10px;
      color: #474F57;
      margin-bottom: 15px;
      line-height: 15px;

      .HealthAnalysisMatrix_bottom_list_leve {
        display: inline-flex;
        flex-shrink: 0;
        width: 16px;
        height: 14px;
        border-radius: 3px;
        margin-right: 5px;
        opacity: 1;
      }

      .HealthAnalysisMatrix_bottom_list_title {
        margin-right: 5px;
      }

      .HealthAnalysisMatrix_bottom_list_text {}

      .HealthAnalysisMatrix_bottom_list_h {
        margin-top: 30px;
        width: 622px;
        height: 0px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 2px solid;
        border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
      }
    }
  }

  .pages4_bottom {
    display: flex;
    margin-top: 25px;
    padding: 0 70px;

    .pages4_bottom_li {
      display: flex;
      align-items: center;
      margin-right: 14px;

      .pages4_bottom_li_left {
        font-size: 11px;
        margin-right: 6px;
        width: 24px;
        height: 13px;
        border-radius: 2px;
      }

      .pages4_bottom_li_right {
        font-size: 11px;
      }
    }
  }
  .HealthAnalysisMatrix_info_right_title_box {
    display: flex;
    justify-content: space-between;

    .HealthAnalysisMatrix_info_right_title {
      display: flex;
      flex-direction: column;
      align-items: center;

      .HealthAnalysisMatrix_info_right_title_li {
        font-size: 16px;
        width: 66px;
        text-align: center;
        // flex-shrink: 0;
      }

      .HealthAnalysisMatrix_info_right_title_leve {
        font-size: 18px;
      }
    }
  }
</style>
