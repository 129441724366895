<template>
  <div class="testReport_pages12 A4height" v-if="isShow">
    <Head :UserInfo="PagesInitData.UserInfo" bgColor="#c5ead2" :title2="title2"></Head>
    <div class="testReport_pages12_title_box">
      <div class="testReport_pages12_title_box_title">
        {{ initData.Title }}
      </div>
      <div class="testReport_pages12_title_box_title_h">

      </div>
    </div>
    <div class="testReport_pages12_content" style="width: 100%;">
      <!-- <div class="testReport_pages12_content_title">
        当肝脏不能分解脂肪时，它们就在肝组织中堆积起来形成脂肪肝。
      </div> -->
      <div class="testReport_pages12_contentA">
        <div class="testReport_pages12_content_left">
          <template v-for="(item, index) in initData.Contents">
            <div class="testReport_pages12_content_left_li" v-for="(item1, index1) in item.Contents" :key="index1">
              <div class="testReport_pages12_content_left_li_left a_flex">
                0{{ index1 + 1 }}
              </div>
              <div class="testReport_pages12_content_left_li_right">
                <!-- <div class="testReport_pages12_content_left_li_right_title">
                  胃酸和消化液的失衡可导致溃疡，这可能是由于：
                </div> -->
                <div class="testReport_pages12_content_left_li_right_text">
                  {{ item1 }}
                </div>
              </div>
            </div>
          </template>
          <!-- <div class="testReport_pages12_content_left_li">
            <div class="testReport_pages12_content_left_li_left a_flex">
              02
            </div>
            <div class="testReport_pages12_content_left_li_right">
              <div class="testReport_pages12_content_left_li_right_title">
                胃酸和消化液的失衡可导致溃疡，这可能是由于：
              </div>
              <div class="testReport_pages12_content_left_li_right_text">
                <div>
                  许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各;
                  许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
                  许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
                </div>
              </div>
            </div>
          </div>
          <div class="testReport_pages12_content_left_li">
            <div class="testReport_pages12_content_left_li_left a_flex">
              03
            </div>
            <div class="testReport_pages12_content_left_li_right">
              <div class="testReport_pages12_content_left_li_right_title">
                胃酸和消化液的失衡可导致溃疡，这可能是由于：
              </div>
              <div class="testReport_pages12_content_left_li_right_text">
                <div>
                  许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="testReport_pages12_content_right">
          <div class="testReport_pages12_content_right_img">
            <img class="wh100"
              src="https://image.giantgocloud.com/www//ImageMapping/image/20231212/22F43740C06A47F6AEE4713396065447.png"
              alt="" />
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import Head from "./head.vue"
export default {
  components: {
    Head,
  },
  props: {
    "PagesInitData": {
      required: true,
      default: ''
    },
  },
  data() {
    return {
      isShow: false,
      initData: '',
      title2:'专业健康知识'
    }
  },
  watch: {
    PagesInitData: {
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        let data = newValueA.Plate8
        data.map(v => {
          let substr2 = v.Title.substr(-2)
          if (substr2 == '病因') {
            this.isShow = true
            this.initData = v
            // console.log(v, 86666666)
          }
        })
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.testReport_pages12 {
  width: 595px;
    height: 841px;
  background-color: #fff;
  overflow: hidden;
  .testReport_pages12_title_box {
    margin-top: 48px;

    .testReport_pages12_title_box_serialNumber {
      padding-left: 73px;
      font-size: 55px;
      font-weight: 400;
      color: #DFE3E8;
    }

    .testReport_pages12_title_box_title {
      padding-left: 72px;
      font-size: 21px;
      font-weight: 500;
      color: #69b287;
    }

    .testReport_pages12_title_box_title_h {
      margin-top: 18px;
      width: 37px;
      height: 1px;
      margin-left: 72px;
      background-color: #69b287;
    }
  }

  .testReport_pages12_content {
    margin-top: 34px;
    padding-left: 70px;

    .testReport_pages12_content_title {
      font-size: 12px;
      font-weight: 400;
      color: #474F57;
    }

    .testReport_pages12_contentA {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;

      .testReport_pages12_content_left {
        .testReport_pages12_content_left_li {
          display: flex;
          align-items: center;
          margin-bottom: 25px;

          .testReport_pages12_content_left_li_left {
            display: flex;
            flex-shrink: 0;
            width: 39px;
            height: 39px;
            background: #69B287;
            border-radius: 50%;
            font-size: 21px;
            font-weight: 400;
            color: #FFFFFF;
            margin-right: 20px;
          }

          .testReport_pages12_content_left_li_right {
            width: 280px;

            .testReport_pages12_content_left_li_right_title {
              font-size: 12px;
              font-weight: 400;
              color: #69B287;
            }

            .testReport_pages12_content_left_li_right_text {
              // margin-top: 9px;
              font-size: 20px;
              zoom: 0.5;
              font-weight: 400;
              color: #474F57;
            }
          }
        }

        .testReport_pages12_content_left_li:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }

      .testReport_pages12_content_right {
        position: relative;
        width: 119px;
        margin-right: 20px;

        .testReport_pages12_content_right_img {
          position: absolute;
          top: 50%;
          transform: translateY(-112px);
          height: 224px;
          width: 119px;
          right: 20px;
        }
      }
    }


  }
}
</style>
