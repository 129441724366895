<template>
    <div class="testReport_pages15 A4height">
        <Head :UserInfo="PagesInitDataA.UserInfo"></Head>
      <div class="testReport_pages4_title_box">
        <div class="testReport_pages4_title_box_serialNumber">
        
        </div>
        <div class="testReport_pages4_title_box_title">
          <div class="testReport_pages4_title_box_title_p">影响你胃健康</div>
          <div class="testReport_pages4_title_box_title_d"> 相关器官/因素分析</div>
        </div>
      </div>
      <div class="analysis">
        <div class="analysis_list" v-for="(item,index) in PagesInitData.LifeItems" :key="index">
          <div class="analysis_list_left">
            <div class="analysis_list_left1">
              {{ item.Title }}
            </div>
  
          </div>
          <div class="analysis_list_trg">
            <img class="imgs"
              src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/7B376D9C9F7E4615A5CC2CB02039A0E9.png"
              alt="">
          </div>
          <div class="analysis_list_right">
            <div class="analysis_list_right_list" v-for="(item1,index1) in item.LifeList" :key="index1">
              <div class="analysis_list_right_title">
               {{ item1.Name }}
              </div>
              <div class="analysis_list_right_text">
                {{ item1.Content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Head from "./head.vue"
  export default {
    data() {
      return {
  
      }
    },
    props: ['PagesInitData',"PagesInitDataA"],
    methods: {
  
    },
    mounted() {
      console.log(this.PagesInitData,'56444444444')
    },
    components: {
      Head,
    },
    
  }
  </script>
  
  <style lang="scss" scoped>
  .testReport_pages15 {
    background-color: #fff;
    width: 595px;
      height: 841px;
      overflow: hidden;
    .testReport_pages4_title_box {
      position: relative;
      width: 90px;
      height: 140px;
      border: 3px solid #DFE3E8;
      margin-top: 48px;
      margin-left: 50px;
  
      .testReport_pages4_title_box_serialNumber {
        font-size: 55px;
        font-weight: 400;
        color: #DFE3E8;
        padding-top: 70px;
        padding-left: 10px;
      }
  
      .testReport_pages4_title_box_title {
        position: absolute;
        left: 20px;
        top: 14px;
        width: 200px;
        background-color: #fff;
        color: #2BD8D8;
        font-size: 24px;
      }
    }
  
    .analysis {
      padding-left: 100px;
      .analysis_list {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        .analysis_list_left {
          width: 100px;
          height: 100px;
          margin: 0 20px;
          color: #474F57;
          border: 5px solid #DECA63;
          border-radius: 50%;
          text-align: center;
          line-height: 100px;
          font-size: 14px;
          .analysis_list_left1{
  
          }
        }
  
        .analysis_list_trg {
          width: 9px;
          height: 17px;
          .imgs {
            width: 100%;
            height: 100%;
          }
        }
  
        .analysis_list_right {
          flex: 1;
          padding-left: 20px;
          .analysis_list_right_list {
            .analysis_list_right_title {
              font-size: 14px;
              color: #DECA63;
              padding-top: 5px;
              margin-top: 10px;
            }
  
            .analysis_list_right_text {
              color: #474F57;
              font-size: 8px;
            }
          }
        }
      }
    }
  }</style>
  