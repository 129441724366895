<template>
    <div class="home">
        <div class="homebox">
            <div class="home_info">
                <div class="home_info_line"></div>
                <div class="home_info_title">
                    <div class="home_info_title1">{{ HomeData.Title }}</div>
                    <div class="home_info_title2">ANALYSIS HEALTH REPORTS</div>
                </div>
            </div>
            <div class="info">
                <div class="info1">姓名：{{ HomeData.UserName }}</div>
                <div class="info2">性别：{{ HomeData.Sex }}</div>
                <div class="info3">年龄：{{ HomeData.Age }}</div>
                <div class="info4">出生日期：{{ HomeData.Birthday }}</div>
                <div class="info5">检测日期：{{ HomeData.ReportDate }}</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: ['HomeData'],
}
</script>
<style lang="scss" scoped>
.home {
    width: 595px;
    height: 841px;
    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20231228/9D5AF36650624CB2A5510291111DA207.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    color: #FFFFFF;
    .homebox {
        position: absolute;
        bottom: 84px;
        left: 245px;
        .home_info {
            text-align: right;
            .home_info_line {
                width: 70px;
                height: 1px;
                border: 1px solid #FFFFFF;
                margin-left: 215px;
                opacity: 0.9;
            }
            .home_info_title {
                .home_info_title1 {
                    font-size: 30px;
                    margin: 10px 0;
                }
                .home_info_title2 {
                    font-size: 12px;
                    opacity: 0.9;
                }
            }
        }
        .info {
            font-size: 20px;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            margin-top: 100px;
                .info1 {
                    padding: 10px 0;
                    border-bottom: 1px solid #fff;
                }
                .info2 {
                    padding: 10px 0;
                    border-bottom: 1px solid #fff;
                }
                .info3 {
                    padding: 10px 0;
                    border-bottom: 1px solid #fff;
                }
                .info4 {
                    padding: 10px 0;
                    border-bottom: 1px solid #fff;
                }
                .info5 {
                    padding: 10px 0;
                    // border-bottom: 1px solid #fff;
                }
            }
    }
}</style>
