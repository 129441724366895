<template>
    <div class="testReport_pages6">
        <Head :UserInfo="PagesInitData.UserInfo"></Head>
        <div class="testReport_pages6_content">
            <div class="testReport_pages6_Num">03</div>
            <div class="testReport_pages6_title" >{{ PagesInitData.Plate3.Title }}</div>
            <div class="testReport_pages6_line"></div>
        </div>
        <div class="testReport_pages6_contenta">
            <div class="triangle"><img
                    src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/A80EA31C4F4145339B03825CB463F296.png"
                    alt=""></div>
            <div class="square">
                <div class="square_list" >
                    <div v-for="(item,index) in PagesInitData.Plate3.Advices" :key="index">
                        <div class="square_listTitle">
                            <div class="icon"></div>
                            <div class="title">{{ item }}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "./head.vue"
export default {
    components: {
      Head,
    },
    props: ['PagesInitData'],
    data() {
        return {
            listdata: [
                { title: '低饱和脂肪和低胆固醇饮食' },
                { title: '食用富含高纤维的食物，如蔬菜、全谷类食物等' },
                { title: '适度饮酒或不饮酒' },
                { title: '戒烟' },
                { title: '坚持运动锻炼' },
                { title: '保持健康体重' },
                { title: '管控妥有关的慢性疾病/健康状况' }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.testReport_pages6 {
    width: 595px;
    height: 841px;
    overflow: hidden;
    padding: 0 60px 0 53px;
    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20231228/FE279657DB814B38ADB294313ECCEE64.png') no-repeat;
    background-size: 100% 100%;

    .testReport_pages6_content {
        .testReport_pages6_Num {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
        }

        .testReport_pages6_title {
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #4A9AAF;
        }

        .testReport_pages6_line {
            width: 37px;
            height: 1px;
            border: 1px solid #4A9AAF;
            margin-top: 17px;
        }
    }

    .testReport_pages6_contenta {
        margin-top: 22px;

        .triangle {
            width: 70px;
            height: 47px;
            margin-left: 90px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .square {
            width: 492px;
            height: 319px;
            background: #6FC5D8;
            border-radius: 38px;
            padding: 46px 54px 121px 54px;

            .square_list {
                .square_listTitle {
                    display: flex;
                    align-items: center;
                    margin: 10px 0;

                    .icon {
                        width: 7px;
                        height: 7px;
                        border: 1px solid #FFFFFF;
                        border-radius: 50%;
                    }

                    .title {
                        font-size: 12px;
                        font-family: Source Han Sans SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}</style>
