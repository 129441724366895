<template>
  <div v-if="initData.length" class="testReport_pages14 A4height">

    <Head :UserInfo="PagesInitData.UserInfo" bgColor="#c5ead2" :title2="title2"></Head>
    <div class="testReport_pages14_title_box">
      <div class="testReport_pages14_title_box_title">
        {{ PagesInitData.Plate8[3].Title }}
      </div>
      <div class="testReport_pages14_title_box_title_h">
      </div>
    </div>
    <div class="testReport_pages14_content">
      <!--  <div class="testReport_pages14_content_title">
        NAFLD 通常不会引起症状,如果脂肪堆积导致肝功能异常即出现    症状,可能包括
      </div> -->
      <div class="testReport_pages14_content_box clearfix">
        <div class="testReport_pages14_content_box_left" style="margin-top: 50px;">
          <div v-for="(item, index) in initData" :key="index">
            <div class="testReport_pages12_content_left">
              <div class="testReport_pages12_content_left_li" >
              <div class="testReport_pages12_content_left_li_left a_flex">
               {{ index + 1 }}
              </div>
              <div class="testReport_pages12_content_left_li_right">
                <div class="testReport_pages12_content_left_li_right_text">
                  {{ item}}
                </div>
              </div>
            </div>
            </div>
            
          </div>


        </div>
        <div class="testReport_pages14_content_box_img">
          <img class="wh100"
            src="https://image.giantgocloud.com/www//ImageMapping/image/20231213/36E960AF0B59440896AC319F5D51660E.png"
            alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "./head.vue"
export default {
  props: {
    "PagesInitData": {
      required: true,
      default: ''
    },
  },
  watch: {
    PagesInitData: {
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        this.initData=newValueA.Plate8[3].Contents[0].Contents;
        console.log('9999999999991',this.initData);
      }
    },
  },
  data() {
    return {
      isShow: false,
      listData: [
      
      ],
      title2:'专业健康知识',
      initData:[]
    }
  },
  methods: {
    hexToRgb(hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace("#", "");
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16);
      var green = parseInt(hex[2] + hex[3], 16);
      var blue = parseInt(hex[4] + hex[5], 16);
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')';
    }
  },
  mounted() {

  },
  components: {
    Head,
  }
}
</script>

<style lang="scss" scoped>
      .testReport_pages12_content_left {
        .testReport_pages12_content_left_li {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .testReport_pages12_content_left_li_left {
            display: flex;
            flex-shrink: 0;
            width: 39px;
            height: 39px;
            background: #69B287;
            border-radius: 50%;
            font-size: 21px;
            font-weight: 400;
            color: #FFFFFF;
            margin-right: 20px;
          }

          .testReport_pages12_content_left_li_right {
            width: 170px;

            .testReport_pages12_content_left_li_right_title {
              font-size: 12px;
              font-weight: 400;
              color: #69B287;
            }

            .testReport_pages12_content_left_li_right_text {
              // margin-top: 9px;
              font-size: 20px;
              zoom: 0.5;
              font-weight: 400;
              color: #474F57;
            }
          }
        }

        .testReport_pages12_content_left_li:nth-last-of-type(1) {
          // margin-bottom: 0;
        }
      }
.testReport_pages14 {
  width: 595px;
  height: 841px;
  background-color: #fff;
  overflow: hidden;

  .testReport_pages14_title_box {
    margin-top: 48px;

    .testReport_pages14_title_box_serialNumber {
      padding-left: 73px;
      font-size: 55px;
      font-weight: 400;
      color: #DFE3E8;
    }

    .testReport_pages14_title_box_title {
      padding-left: 72px;
      font-size: 21px;
      font-weight: 500;
      color: #69b287;
    }

    .testReport_pages14_title_box_title_h {
      margin-top: 18px;
      width: 37px;
      height: 1px;
      margin-left: 72px;
      background-color: #69b287;
    }
  }

  .testReport_pages14_content {
    margin-top: 34px;

    .testReport_pages14_content_title {
      width: 336px;
      margin-left: 69px;
      font-size: 12px;
      font-weight: 400;
      color: #474F57;
      line-height: 30px;
    }

    .testReport_pages14_content_box {
      position: relative;

      .testReport_pages14_content_box_left {
        margin-left: 103px;

        .testReport_pages14_content_box_left_li {
          .testReport_pages14_content_box_left_li_text {
            font-size: 14px;
            color: #69B287;
            margin-right: 10px;
            height: 18px;
          }

          .testReport_pages14_content_box_left_li_x {
            position: relative;
            flex: 1;

            .testReport_pages14_content_box_left_li_x_h {
              width: 100%;
              background-color: #69B287;
              border-bottom: 1px solid #69B297;
              // height:1px;
            }

            .testReport_pages14_content_box_left_li_x_h1 {
              position: absolute;
              right: 0;
              background-color: #fff;
              height: 100%;
              flex: 1;
              height: 1px;
              z-index: 1;
            }

            .testReport_pages14_content_box_left_li_x_y {
              position: absolute;
              right: -8px;
              top: 50%;
              transform: translateY(-4px);
              z-index: 1;
              width: 8px;
              height: 8px;
              background: #FFFFFF;
              border-radius: 50%;
            }
          }
        }
      }

      .testReport_pages14_content_box_img {
        position: absolute;
        top: -30px;
        left: 340px;
        width: 242px;
        height: 557px;
      }
    }
  }
}</style>
