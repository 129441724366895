<template>
  <div class="testReport_pages1">
    11111111111111
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
  .testReport_pages1{
    width: 595px;
    height: 841px;
    overflow: hidden;
  }
</style>
