<template>
  <div v-if="PagesInitData&&isShow" class="testReport_pages15 A4height">
    <Head :UserInfo="PagesInitData.UserInfo" bgColor="#c5ead2" :title2="title2"></Head>
    <div class="testReport_pages15_title_box">
      <div class="testReport_pages15_title_box_title">
          {{initData.Title}}
      </div>
      <div class="testReport_pages15_title_box_title_h">
      </div>
    </div>
    <div class="testReport_pages15_content">
<!--      <div class="testReport_pages15_content_title">
        NAFLD 主要并发症是肝硬化这是肝脏后期的癫痕形成(纤维化)造成的
        {{initData.Title}}
      </div> -->
      <div class="testReport_pages15_content_box clearfix">
        <div class="testReport_pages15_content_box_img">
          <div class="testReport_pages15_content_box_img_box wh100 clearfix">
            <img class="wh100" style="position: absolute;" src="https://image.giantgocloud.com/www//ImageMapping/image/20231214/DA7F0CBF2366468C80502D4519125B01.png" alt="" />
            <div class="testReport_pages15_content_box_img_box_text_box">
            <!--  <div class="testReport_pages15_content_box_img_box_text_title">
                肝硬化可导致:
              </div> -->
              <div class="testReport_pages15_content_box_img_box_text_title_ul">
                <div v-for="(item,index) in initData.Contents[0].Contents" :key="index" class="testReport_pages15_content_box_img_box_text_title_li">
                  {{item}}
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
   <!--   <div class="testReport_pages15_bottom">
        终未期肝衰竭，这意味着肝脏已停止运作。大约20% 的非酒精
        性脂肪性肝炎患者会发展为肝硬化。
      </div> -->
    </div>
  </div>
</template>

<script>
  import Head from "./head.vue"

  export default {
    props: {
      "PagesInitData": {
        required: true,
        default: ''
      },
    },
   watch:{
       PagesInitData: {
       deep: true, // 开启深度监听 专门对付复杂数据类型
       handler: function (newValueA, oldValue) {
           let data = newValueA.Plate8;
           data.map(v => {
               // if(v.Title)
               let substr2 = v.Title.substr(-2)
               if(substr2 == '发症'){
                   this.initData = v;
                   this.isShow=true;
                  //  console.log(v,86666666)
               }
               // if(v. = "")
               // console.log(substr2)
           })
          //  console.log('999999999999',newValueA.Plate8);
          //  console.log('initData 15',this.initData);

       }
   },
   },
    data(){
      return{
        isShow: false,
        title2:'专业健康知识',
        listData:[
          {
            type:0,
            text:'易疲劳'
          },
          {
            type:1,
            text:'肝肿大'
          },
          {
            type:2,
            text:'皮肤发黄'
          },
          {
            type:3,
            text:'皮肤发黄'
          },
          {
            type:4,
            text:'皮肤发黄'
          },
          {
            type:5,
            text:'肝肿大'
          },
          {
            type:6,
            text:'肝肿大'
          },
          {
            type:7,
            text:'皮肤发黄'
          },
          {
           type:8,
           text:'肝肿大'
          },
          {
           type:9,
           text:'皮肤发黄'
          },
        ]
      }
    },
    methods:{
hexToRgb(hex, opt) {
                // Remove "#" symbol from the beginning of the string
                hex = hex.replace("#", "");
                // Convert each pair of characters to decimal values for red, green, and blue
                var red = parseInt(hex[0] + hex[1], 16);
                var green = parseInt(hex[2] + hex[3], 16);
                var blue = parseInt(hex[4] + hex[5], 16);
                return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')';
            }
    },
    mounted() {

    },
    components:{
      Head,
    }
  }
</script>

<style lang="scss" scoped>
  .testReport_pages15{
    width: 595px;
    height: 841px;
    background-color: #fff;
    overflow: hidden;
    .testReport_pages15_title_box{
      margin-top: 48px;
      .testReport_pages15_title_box_serialNumber{
        padding-left: 73px;
        font-size: 55px;
        font-weight: 400;
        color: #DFE3E8;
      }
      .testReport_pages15_title_box_title{
        padding-left: 72px;
        font-size: 21px;
        font-weight: 500;
        color: #69b287;
      }
      .testReport_pages15_title_box_title_h{
        margin-top: 18px;
        width: 37px;
        height: 1px;
        margin-left: 72px;
        background-color: #69b287;
      }
    }
    .testReport_pages15_content{
      margin-top: 34px;
      .testReport_pages15_content_title{
        margin-left:69px;
        font-size: 12px;
        font-weight: 400;
        color: #474F57;
        line-height: 30px;
      }
      .testReport_pages15_content_box{
        position: relative;
        margin-top: 28px;
        .testReport_pages15_content_box_img{
          width:535px;
          height:455px;
          position: absolute;
          right: 0;
          .testReport_pages15_content_box_img_box{
            position: relative;
            .testReport_pages15_content_box_img_box_text_box{
              position: relative;
              z-index:1;
              margin-top:140px;
              margin-left: 296px;
              .testReport_pages15_content_box_img_box_text_title{
                font-size: 21px;
                font-weight: 500;
                color: #FFFFFF;
              }
              .testReport_pages15_content_box_img_box_text_title_ul{
                margin-top: 28px;
                .testReport_pages15_content_box_img_box_text_title_li{
                  font-size: 14px;
                  font-weight: 400;
                  color: #FFFFFF;
                  margin-bottom: 5px;
                  line-height: 28px;
                }
              }
            }
          }
        }

      }
      .testReport_pages15_bottom{
        width:318px;
        margin-left: 70px;
        margin-top: 490px;
        font-size: 12px;
        font-weight: 400;
        color: #474F57;
        line-height: 24px;
      }
    }
  }

</style>
