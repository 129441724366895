<template>
    <div class="testReport_pages6" v-if="PagesInitData">

        <Head :UserInfo="PagesInitData.UserInfo"></Head>
        <div class="testReport_pages6_content">
            <div class="testReport_pages6_Num">04</div>
            <div class="testReport_pages6_title">营养建议</div>
            <div class="testReport_pages6_line"></div>
        </div>
        <div class="testReport_pages6_contenta">
            <div class="testReport_pages6_contenta1">
                以下是基于循证医学和循证营养学有关文献综合的结果。主要包括如下：
            </div>
            <div class="testReport_pages6_contenta2">
                <div class="box">
                    <div class="box1_content1">{{ PagesInitData.Plate5.MainFunctionTitle }}:</div>
                    <div class="box1">
                        <div class="box1_content2" v-for="(item, index) in PagesInitData.Plate5.MainFunctionList">
                            <div class="box1_content2_title">
                                <div class="box1_content2_title1">
                                    {{ index + 1 }}. {{ item.FunctionName }}
                                </div>
                                <div class="box1_content2_title1_line">
                                </div>
                                <div class="box1_content2_title1_cil">
                                </div>
                            </div>
                            <div class="box1_content3">
                                <div class="square_listTitle" v-for="(item1, index1) in item.Nutritions" :key="index1">
                                    <div class="icon1"></div>
                                    <div class="title"><span>{{ item1.Effect
                                    }}</span><span>&nbsp;&nbsp;:&nbsp;</span><span>{{ item1.Component }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="box1_content1">{{ PagesInitData.Plate5.FactorFunctionTitle }}:</div>
                    <div class="box1">
                        <div class="box1_content2" v-for="(item, index) in PagesInitData.Plate5.FactorFunctionList">
                            <div class="box1_content2_title">
                                <div class="box1_content2_title1">
                                    {{ index + 1 }}. {{ item.FunctionName }}
                                </div>
                                <div class="box1_content2_title1_line">
                                </div>
                                <div class="box1_content2_title1_cil">
                                </div>
                            </div>
                            <div class="box1_content3">
                                <div class="square_listTitle" v-for="(item1, index1) in item.Nutritions" :key="index1">
                                    <div class="icon1"></div>
                                    <div class="title">{{ item1 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box" v-if="PagesInitData.Plate5.EatTitle && PagesInitData.Plate5.EatAdvices">
                    <div class="box1_content1">{{ PagesInitData.Plate5.EatTitle }}:</div>
                    <div class="box1">
                        <div class="box1_content2" v-for="(item, index) in PagesInitData.Plate5.EatAdvices">
                            <div class="box1_content2_title">
                                <div class="box1_content2_title1">
                                    {{ index + 1 }}. {{ item }}
                                </div>
                                <!-- <div class="box1_content2_title1_line">
                                </div> -->
                                <!-- <div class="box1_content2_title1_cil">
                                </div> -->
                            </div>
                            <div class="box1_content3">
                                <div class="square_listTitle" v-for="(item1, index1) in item.Nutritions" :key="index1">
                                    <div class="icon1"></div>
                                    <div class="title">{{ item1 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="testReport_pages6_contentb">
            <img src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/2163359B4AAE4B97BB4C534B794B9F6A.png"
                alt="">
        </div>
    </div>
</template>

<script>
import Head from "./head.vue"
export default {
    components: {
        Head,
    },
    props: ['PagesInitData'],
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.testReport_pages6 {
    width: 595px;
    height: 841px;
    padding: 0 60px 0 53px;
    position: relative;
    overflow: hidden;

    .testReport_pages6_content {
        .testReport_pages6_Num {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
            // margin-bottom: 30px;
        }

        .testReport_pages6_title {
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #4A9AAF;
        }

        .testReport_pages6_line {
            width: 37px;
            height: 1px;
            border: 1px solid #4A9AAF;
            margin-top: 17px;
        }
    }

    .box1_content3 {
        // margin-top: 15px;

        .square_listTitle {
            display: flex;
            align-items: center;
            font-size: 10px;
            color: #000;
            margin: 5px 0;

            .icon1 {
                width: 8px;
                height: 8px;
                border: 1px solid #000;
                border-radius: 50%;
            }

            .title {
                margin-left: 3px;
            }
        }
    }

    .testReport_pages6_contenta {
        margin-top: 20px;
        width: 368px;

        .testReport_pages6_contenta1 {
            width: 304px;
            font-size: 12px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #474F57;
            line-height: 18px;
        }

        .testReport_pages6_contenta2 {
            font-size: 14px;
            font-weight: 500;
            color: #4A9AAF;

            .box {
                .box1_content1 {
                    font-size: 14px;
                    font-weight: 600;
                    margin: 10px 0 0 0;
                }

                .box1 {
                    .box1_content2 {
                        .box1_content2_title {
                            display: flex;
                            align-items: center;
                            margin: 10px 0;

                            .box1_content2_title1 {
                                font-size: 12px;
                                color: #000;
                                font-weight: 600;
                            }

                            .box1_content2_title1_line {
                                width: 170px;
                                height: 1px;
                                background-color: #4A9AAF;
                                flex: 1;
                            }

                            .box1_content2_title1_cil {
                                width: 5px;
                                height: 5px;
                                background: #4A9AAF;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
    }

    .testReport_pages6_contentb {
        width: 227px;
        height: 444px;
        position: absolute;
        right: 0;
        top: 150px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}</style>
