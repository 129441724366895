<template>
    <div>
        <div class="testReport_pages3">
        <div class="title_borderground">
            <div class="title_borderground_content1">{{ PagesInitData }}</div>
            <div class="title_borderground_content2">精准分析报告</div>
            <div class="title_borderground_content3"></div>
        </div>
    </div>
    </div>

</template>

<script>
export default {
    props: ['PagesInitData'],
  data () {
    return {

    }
  },
  mounted(){
  }
}
</script>

<style lang="scss" scoped>
.testReport_pages3 {
    width: 595px;
    height: 841px;
    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20231228/F0B1F293E7AB4E4283D083380E901401.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    // margin-top: 30px;
    .title_borderground {
        font-size: 32px;
        color: #4A9AAF;
        position: absolute;
        bottom: 280px;
        left: 85px;
        text-align: right;
        font-weight: 400;
        .title_borderground_content1 {}

        .title_borderground_content2 {}
            // border-bottom:1px solid #4A9AAF;
        .title_borderground_content3 {
            width: 31px;
            height: 1px;
            border: 0.5px solid #4A9AAF;
            margin-left: 158px;
            margin-top: 12px;
        }
    }
}
</style>
