<template>
    <div class="testReport_pages8">
        <Head :UserInfo="PagesInitData.UserInfo"></Head>
        <div class="testReport_pages8_content">
            <div class="testReport_pages8_Num">05</div>
            <div class="testReport_pages8_title">医疗干预</div>
            <div class="testReport_pages8_line"></div>
        </div>
        <div class="content8">
            <div class="content8_box">
                <div class="content8_title1">
                    {{ PagesInitData.Plate6.Introduce }}
                </div>
                <div class="content8_title2">
                    <!-- <div class="content8_small_title">
                        <div class="content8_small_title_cil"></div>
                        <div class="content8_small_title_tit">贝特类调脂药</div>
                    </div> -->
                    <div class="content8_small_content" v-for="(item, index) in  PagesInitData.Plate6.Illustrates"
                        :key="index">
                        {{ item }}
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="references">
            <div class="referencesTitle">参考文献:</div>
            <div class="referencesContent">1. Drenjancevic I et al. Omega-3 Polyunsaturated Fatty Acids-Va1. Drenjancevic I
                et al. Omega-3 Polyunsaturated Fatty Acids-Va1. Drenjancevic I et al. Omega-3 Polyunsaturated Fatty
                Acids-Va1. Drenjancevic I et al. Omega-3 Polyunsaturated Fatty Acids-Va1. Drenjancevic I et al. Omega-3
                Polyunsaturated Fatty Acids-Va</div>
        </div> -->
    </div>
</template>

<script>
import Head from "./head.vue"
export default {
    components: {
      Head,
    },
    props: ['PagesInitData'],
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.testReport_pages8 {
    width: 595px;
    height: 841px;
    padding: 0 60px 0 53px;
    position: relative;
    overflow: hidden;

    .testReport_pages8_content {
        .testReport_pages8_Num {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
            margin: 30px 0;
        }

        .testReport_pages8_title {
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #4A9AAF;
        }

        .testReport_pages8_line {
            width: 37px;
            height: 1px;
            border: 1px solid #4A9AAF;
            margin-top: 17px;
        }
    }
}

.content8 {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #4A9AAF;
    margin-top: 34px;

    .content8_box {
        .content8_title1 {
            font-weight: 600;
            margin-top: 20px;
        }

        .content8_title2 {
            margin-top: 10px;

            .content8_small_title {
                display: flex;
                align-items: center;

                .content8_small_title_cil {
                    width: 8px;
                    height: 8px;
                    border: 1px solid #4A9AAF;
                    border-radius: 50%;
                }

                .content8_small_title_tit {
                    font-size: 18px;
                    font-family: Source Han Sans SC;
                    font-weight: 400;
                    color: #4A9AAF;
                    margin-left: 5px;
                }
            }

            .content8_small_content {
                font-size: 10px;
                font-family: Source Han Sans SC;
                font-weight: 400;
                color: #474F57;
                // margin-left: 15px;
                margin: 15px;
            }
        }
    }

}

.references {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #4A9AAF;
    margin-left: 5px;
    margin-top: 40px;

    .referencesContent {
        font-size: 8px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #474F57;
        margin-top: 30px;
    }

}
</style>
