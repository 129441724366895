<template>
    <div class="testReport">
      <Home :HomeData="HomeData"></Home>
      <Pages2 :Pages2Data="Pages2Data"></Pages2>
      <Pages4 :PagesInitData="PagesInitData"></Pages4>
      <Pages5 :PagesInitData="PagesInitData"></Pages5>
      <Pages6 :PagesInitData="PagesInitData" v-if="PagesInitData && PagesInitData.Plate3.Title"></Pages6>
      <Pages7 :PagesInitData="PagesInitData"></Pages7>
      <div v-if="PagesInitData && PagesInitData.Plate6.Title">
        <Pages8 :PagesInitData="PagesInitData"></Pages8>
      </div>
      <div v-for="(item, index) in FoodList" :key="index">
        <Pages9 :Pages9Data="item" :curentIndex="index" :UserInfo="PagesInitData.UserInfo"></Pages9>
      </div>
      <!-- <div v-for="(item2,index2) in PagesInitData.Plate7" :key="index2"> -->
      <div v-for="site in PagesInitData.Plate7">
        <pages10 :idIndex="site.index2" :PagesInitData="site.ItemName" :PagesInitDataA="PagesInitData"></pages10>
        <Pages17 :idIndex="site.index2" :PagesInitData="site" :PagesInitDataA="PagesInitData"></Pages17>
      </div>
      <!-- <pages10 :idIndex="0" :PagesInitData="PagesInitData.Plate7[0].ItemName" :PagesInitDataA="PagesInitData"></pages10>
        <Pages17 :idIndex="0" :PagesInitData="PagesInitData.Plate7[0]" :PagesInitDataA="PagesInitData"></Pages17>
        <pages10 :idIndex="1" :PagesInitData="PagesInitData.Plate7[1].ItemName" :PagesInitDataA="PagesInitData"></pages10>
        <Pages17 :idIndex="1" :PagesInitData="PagesInitData.Plate7[1]" :PagesInitDataA="PagesInitData"></Pages17> -->
      <div v-if="PagesInitData.FunctionPlate7&&PagesInitData.FunctionPlate7.length>0">
        <div v-for="(item1,index1) in PagesInitData.FunctionPlate7" :key="index1">
          <Pages18 :PagesInitData="item1" :PagesInitDataA="PagesInitData"/>
          <Pages19 :PagesInitData="item1" :PagesInitDataA="PagesInitData"/>
          <Pages20 :PagesInitData="item1" :PagesInitDataA="PagesInitData"/>
        </div>
        
      </div>
      <Pages11 :PagesInitData="PagesInitData"></Pages11>
      <Pages12 :PagesInitData="PagesInitData"></Pages12>
      <Pages13 :PagesInitData="PagesInitData"></Pages13>
      <Pages14 :PagesInitData="PagesInitData"></Pages14>
      <Pages15 :PagesInitData="PagesInitData"></Pages15>
  </div>
</template>

<script>
import Head from "./testReport/head.vue"
import Home from "./testReport/home.vue"
import Pages1 from "./testReport/pages1.vue"
import Pages2 from "./testReport/pages2.vue"
import Pages4 from "./testReport/pages4.vue"
import Pages5 from "./testReport/pages5.vue"
import Pages6 from "./testReport/pages6.vue"
import Pages7 from "./testReport/pages7.vue"
import Pages8 from "./testReport/pages8.vue"
import Pages9 from "./testReport/pages9.vue"
import Pages10 from "./testReport/pages10.vue"
import Pages11 from "./testReport/pages11.vue"
import Pages12 from "./testReport/pages12.vue"
import Pages13 from "./testReport/pages13.vue"
import Pages14 from "./testReport/pages14.vue"
import Pages15 from "./testReport/pages15.vue"
import Pages16 from "./testReport/pages16.vue"
import Pages17 from "./testReport/pages17.vue"
import Pages18 from "./testReport/pages18.vue"
import Pages19 from "./testReport/pages19.vue"
import Pages20 from "./testReport/pages20.vue"
import H5Report from '@/api/index'
import axios from 'axios'
export default {
  data() {
    return {
      HomeData: '',
      Pages2Data: '',
      Pages6Data: '',
      PagesInitData: '',
      FoodList: ''
    }
  },
  components: {
    
    Home,
    Head,
    Pages1,
    Pages2,
    Pages4,
    Pages5,
    Pages6,
    Pages7,
    Pages8,
    Pages9,
    Pages10,
    Pages11,
    Pages12,
    Pages13,
    Pages14,
    Pages15,
    Pages16,
    Pages17,
    Pages18,
    Pages19,
    Pages20
  },
  mounted() {
    let that = this
    const id = this.$query('id')
    axios
      .post(
        // `https://healthingcoretest.giantgocloud.com/api/SceneCheckPage/GetPrecisePdfPageData`,
        `${H5Report}/api/SceneCheckPage/GetPrecisePdfPageData`,
        {
          id: id
        }
      ).then(res => {
        console.log("8888888")
        let data = res.data.data
        for(let i=0;i<data.Plate7.length;i++){
          data.Plate7[i].index2=i;
        }
        this.PagesInitData = data
        this.HomeData = data.UserInfo
        this.Pages2Data = data.Plate1
        this.Pages6Data = data.Plate3
        let FoodName = data.Plate4.SoupList.map(item => item.Name).join(",")
        console.log("77777")
        // 请求食疗
        axios
          .post(
            `${H5Report}/api/SceneCheck/GetFoodWayDetail`,
            {
              FoodName
            }
          ).then(res1 => {
            this.FoodList = res1.data.data
            // console.log(this.FoodList, '食疗数据')
          })


        console.log(data, 854977)
      })
  }
}
</script>

<style lang='scss' scoped>
.testReport {
  width: 595px;
}

.A4height {
  height: 841px;
  // overflow: hidden;
}
</style>
