<template>
  <div>
    <div v-for="(item0,index0) in result" :key="index0">
    <div class="testReport_pages15 A4height">
      <Head :UserInfo="PagesInitDataA.UserInfo"></Head>
      <div class="testReport_pages4_title_box">
        <div class="testReport_pages4_title_box_serialNumber">
    
        </div>
        <div class="testReport_pages4_title_box_title">
          <div class="testReport_pages4_title_box_title_p">影响你胃健康</div>
          <div class="testReport_pages4_title_box_title_d"> 相关器官/因素分析</div>
        </div>
      </div>
      <div class="contents">
        <div class="left">
          <div v-for="(item1, index1) in item0" :key="index1">
            <div class="left_title">
              <div class="left_title_img">
                <img class="imgs" :src="item1.OrganPic" alt="">
              </div>
              <div class="left_title_pross">
                <div class="left_title_pross_text">
                  {{ item1.Name }}
                </div>
                <div class="left_title_pross_w">
                  <div class="left_title_pross_z" v-if="item1.ColorType==1" :style="'width:25px;background:'+item1.ColorCode"></div>
                  <div class="left_title_pross_z" v-if="item1.ColorType==2" :style="'width:50px;background:'+item1.ColorCode"></div>
                  <div class="left_title_pross_z" v-if="item1.ColorType==3" :style="'width:75px;background:'+item1.ColorCode"></div>
                  <div class="left_title_pross_z" v-if="item1.ColorType==4" :style="'width:100px;background:'+item1.ColorCode"></div>
                </div>
              </div>
            </div>
            <div class="left_title_text">
              {{ item1.Content }}
            </div>
          </div>
          
        </div>
        <div class="rgight">
          <img class="imgs"
            src="https://image.giantgocloud.com/www//ImageMapping/image/20231226/956001B07CC24310B3C08E15E1FC6B09.png"
            alt="">
        </div>
      </div>
    </div>
  </div>
  </div>

</template>
  
<script>
import Head from "./head.vue"
export default {
  data() {
    return {
      obj1: '',
      result:[]
    }
  },
  methods: {

  },
  mounted() {
    console.log(this.PagesInitData, '666666666666666666666')
    let data = this.PagesInitData.OrganItems
    let num = 2;
    for (let i = 0, len = data.length; i < len; i += num) {
      this.result.push(data.slice(i, i + num));
    }
    console.log(this.result, '分割出来的数组')
    console.log(this.result, '分割出来的数组')
  },
  props: ['PagesInitData',"PagesInitDataA"],
  components: {
    Head,
  }
}
</script>
  
<style lang="scss" scoped>
.testReport_pages15 {
  background-color: #fff;
  width: 595px;
  height: 841px;
  // padding: 0 60px 0 53px;
  position: relative;
  overflow: hidden;

  .testReport_pages4_title_box {
    position: relative;
    width: 90px;
    height: 140px;
    border: 3px solid #DFE3E8;
    margin-top: 48px;
    margin-left: 50px;

    .testReport_pages4_title_box_serialNumber {
      font-size: 55px;
      font-weight: 400;
      color: #DFE3E8;
      padding-top: 70px;
      padding-left: 10px;
    }

    .testReport_pages4_title_box_title {
      position: absolute;
      left: 20px;
      top: 14px;
      width: 200px;
      background-color: #fff;
      color: #2BD8D8;
      font-size: 24px;
    }
  }

  .contents {
    display: flex;
    justify-content: space-between;
    // padding-top: 60px;

    .left {
      flex: 1;
      margin-left: 60px;

      .left_title {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .left_title_img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 2px solid #5EC692;
          background: #fff;
          z-index: 9 !important;

          .imgs {
            width: 100%;
            height: 100%;
          }
        }

        .left_title_pross {
          margin-left: -10px;
          margin-bottom: 12px;

          .left_title_pross_text {
            font-size: 14px;
            color: #474F57;
            padding-left: 8px;
          }

          .left_title_pross_w {
            // background-color: #5EC692;
            // width: 100px;
            height: 24px;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;

            .left_title_pross_z {
              border-top-right-radius: 15px;
              border-bottom-right-radius: 15px;
              height: 24px;
              // width: 30px;
            }
          }
        }
      }

      .left_title_text {
        color: #474F57;
        font-size: 12px;
        padding-left: 30px;
        padding-top: 10px;
      }
    }

    .rgight {
      width: 208px;
      min-height: 400px;

      .imgs {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
  