<template>
  <div class="testReport_pages2 A4height">
    <div class="testReport_pages2_top">
      <div class="testReport_pages2_top_text1">
        {{ Pages2Data.WelcomeTitle }}
      </div>
      <div class="testReport_pages2_top_text2">
        {{ Pages2Data.WelcomeMsg }}
      </div>
    </div>
    <div class="testReport_pages2_info1">
      <div class="testReport_pages2_info1_title">
        {{ Pages2Data.MainFunctionTitle }}
      </div>
      <div class="testReport_pages2_info1_ul">
        <div class="testReport_pages2_info1_li" v-for="(item, index) in Pages2Data.MainFunctionList" :key="index">
          <template v-if="item.FunctionName && item.FunctionLevel">
          <div class="testReport_pages2_info1_li_left a_flex" :style="{ color: item.FunctionColor, borderColor:item.FunctionColor,backgroundColor:hexToRgb(item.FunctionColor,0.1)}">
            <div class="testReport_pages2_info1_li_left_d" :style="{backgroundColor:item.FunctionColor}"></div>
            <div class="testReport_pages2_info1_li_left_text">{{ item.FunctionName }}</div>
          </div>
          <div class="testReport_pages2_info1_li_right a_flex" :style="{ color: item.FunctionColor, borderColor:item.FunctionColor,backgroundColor:hexToRgb(item.FunctionColor,0.1)}">
            {{ item.FunctionLevel }}
          </div>
          </template>
          <div class="testReport_pages2_info1_li_leftimg" style="width: 120px;height:40px" v-else>
            <img :src="item.FunctionPic" alt="" style="width: 100%;height: 100%;">
          </div>
        </div>
      </div>
    </div>
    <div class="testReport_pages2_info2">
      <div class="testReport_pages2_info2_title">
        {{ Pages2Data.FactorFunctionTitle }}
      </div>
      <div class="testReport_pages2_info2_ul">
        <div class="testReport_pages2_info2_li" v-for="(item, index) in Pages2Data.FactorFunctionList" :key="index">
          <template v-if="item.FunctionLevel">
            <div class="testReport_pages2_info2_li_left a_flex" :style="{ color: item.FunctionColor, borderColor:item.FunctionColor,backgroundColor:hexToRgb(item.FunctionColor,0.1)}">
              {{ item.FunctionLevel }}
            </div>
            <div class="testReport_pages2_info2_li_text" :style="{color: item.FunctionColor}">
              {{ item.FunctionName }}
            </div>
          </template>
          <template v-else>
            <div class="testReport_pages2_info2_li_img">
              <img class="img" :src="item.FunctionPic" alt="">
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="testReport_pages2_info3">
      <div class="testReport_pages2_info3_title">
        {{ Pages2Data.LifeTitle }}
      </div>
      <div class="testReport_pages2_info3_ul">
        <div class="testReport_pages2_info3_li a_flex" v-for="(item, index) in Pages2Data.LifeList" :key="index" :style="{color:item.Color,backgroundColor:hexToRgb(item.Color,0.1)}">
          {{ item.Title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods:{
    hexToRgb(hex, opt) {
        // Remove "#" symbol from the beginning of the string
        hex = hex.replace("#", "");
        // Convert each pair of characters to decimal values for red, green, and blue
        var red = parseInt(hex[0] + hex[1], 16);
        var green = parseInt(hex[2] + hex[3], 16);
        var blue = parseInt(hex[4] + hex[5], 16);
        return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')';
      }
  },
  props: ['Pages2Data'],

}

</script>

<style lang="scss" scoped>
.testReport_pages2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 0 48px;
  background-color: #fff;
  width: 595px;
    height: 841px;

  .testReport_pages2_top {
    line-height: 28px;

    .testReport_pages2_top_text1 {
      color: #4A9AAF;
      font-size: 24px;
      ;
    }

    .testReport_pages2_top_text2 {
      color: #474F57;
      font-size: 12px;
    }
  }

  .testReport_pages2_info1 {
    margin-top: 10px;

    .testReport_pages2_info1_title {
      font-size: 21px;
      font-weight: 500;
      color: #4A9AAF;
    }

    .testReport_pages2_info1_ul {
      display: flex;
      margin-top: 31px;

      .testReport_pages2_info1_li {
        margin-bottom: 30px;
        ;
        display: flex;
        align-items: center;
        margin-right: 72px;

        .testReport_pages2_info1_li_left {
          padding: 9px 11px;
          background: #FAE8EA;
          border-radius: 6px;
          margin-right: 15px;

          .testReport_pages2_info1_li_left_d {
            margin-right: 5px;
            width: 4px;
            height: 4px;
            // background: #D51931;
            border-radius: 50%;
          }

          .testReport_pages2_info1_li_left_text {
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            // color: #D51931;
          }
        }

        .testReport_pages2_info1_li_right {
          width: 34px;
          height: 30px;
          background: #FAE8EA;
          // border: 1px solid #D83346;
          border-radius: 5px;
          font-size: 20px;
          font-weight: normal;
          // color: #D51931;
        }
      }
    }
  }

  .testReport_pages2_info2 {
    // margin-top: 30px;

    .testReport_pages2_info2_title {
      font-size: 21px;
      font-weight: 500;
      color: #4A9AAF;
    }

    .testReport_pages2_info2_ul {
      width: 70%;
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;

      .testReport_pages2_info2_li {
        display: flex;
        align-items: center;
        // margin-right: 52px;
        margin-bottom: 21px;
        width: 50%;

        .testReport_pages2_info2_li_left {
          margin-right: 8px;
          width: 31px;
          height: 27px;
          background: #FAE8EA;
          border: 1px solid ;
          border-radius: 5px;
          font-size: 18px;
          font-weight: normal;
          // color: #D51931;
        }

        .testReport_pages2_info2_li_text {
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          // color: #D51931;
        }

        .testReport_pages2_info2_li_img {
          width: 120px;
          height: 38px;
        }
      }
    }
  }

  .testReport_pages2_info3 {
    // margin-top: 30px;

    .testReport_pages2_info3_title {
      font-size: 21px;
      font-weight: 500;
      color: #4A9AAF;
    }

    .testReport_pages2_info3_ul {
      margin-top: 25px;

      .testReport_pages2_info3_li {
        display: inline-flex;
        margin-bottom: 20px;
        margin-right: 22px;
        padding: 8px 6px;
        background: #FEF9E8;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 400;
        // color: #EEC122;
      }
    }
  }
}
</style>
