<template>
    <div class="testReport_pages11" v-if="isShow">
        <Head :UserInfo="PagesInitData.UserInfo" bgColor="#c5ead2" :title2="title2"></Head>
        <div class="testReport_pages11_content">
            <div class="testReport_pages11_Num"></div>
            <div class="testReport_pages11_title">{{ initData.Title }}</div>
            <div class="testReport_pages11_line"></div>
        </div>
        <div class="content">
            <div class="contanta" v-for="(item, index) in initData.Contents" :key="index">
                <div v-for="(item1, index1) in item.Contents" :key="index1">{{ item1 }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "./head.vue"
export default {
    components:{
      Head,
    },
    props: {
        "PagesInitData": {
            required: true,
            default: ''
        },
    },
    data() {
        return {
            isShow: false,
            initData: '',
            title2:'专业健康知识'
        }
    },
    watch: {
        PagesInitData: {
            deep: true, // 开启深度监听 专门对付复杂数据类型
            handler: function (newValueA, oldValue) {
                let data = newValueA.Plate8
                data.map(v => {
                    let substr2 = v.Title.substr(-2)
                    if (substr2 == '定义') {
                        this.isShow = true
                        this.initData = v
                        // console.log(v, 86666666)
                    }
                })
            }
        },
    },

}
</script>

<style lang="scss" scoped>
.testReport_pages11 {
    width: 595px;
    height: 841px;
    padding: 0 60px 0 53px;
    position: relative;
    overflow: hidden;
    // background:url('https://image.giantgocloud.com/www//ImageMapping/image/20231214/EA2ED5BBC1624457BA6A84115C791545.png') no-repeat;
    background-size: 100% 100%;

    .testReport_pages11_content {
        margin-top: 30px;
        .testReport_pages11_Num {
            font-size: 55px;
            font-weight: 400;
            color: #DFE3E8;
        }

        .testReport_pages11_title {
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #4A9AAF;
        }

        .testReport_pages11_line {
            width: 37px;
            height: 1px;
            border: 1px solid #4A9AAF;
            margin-top: 17px;
        }
    }

}

.content {
    width: 498px;
    height: 543px;
    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20231228/4D7F047DFE1C4A7788F12C00E48DF1CD.png') no-repeat;
    background-size: cover;
    padding-left: 40px;
    padding-top: 60px;
    font-size: 12px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #474F57;

    .contanta {
        margin: 20px 0;
        line-height: 25px;
        letter-spacing: 2px;
    }
}
</style>
