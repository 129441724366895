<template>
  <div class="testReport_pages13 A4height" v-if="isShow">
    <Head :UserInfo="PagesInitData.UserInfo" bgColor="#c5ead2" :title2="title2"></Head>
    <div class="testReport_pages13_content">
      <div class="testReport_pages13_content_bg">
        <img class="wh100"
          src="https://image.giantgocloud.com/www//ImageMapping/image/20231212/5BDE7FB33A6E4F059CB06C9E18DE3D91.png"
          alt="" />
      </div>
      <div class="testReport_pages13_content_text">
          {{ initData.Title }}
        </div>
      <div style="position: relative;margin-top: 100px;">
       
        <template v-for="(item, index) in initData.Contents">
          <div class="testReport_pages13_content_ul" v-for="(item1, index1) in item.Contents" :key="index1">
            <div class="testReport_pages13_content_li">
              <div class="testReport_pages13_content_li_li">
                <div class="testReport_pages13_content_li_li_left"></div>
                <div class="testReport_pages13_content_li_li_text">
                  {{ item1 }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import Head from "./head.vue"
export default {
  components: {
    Head,
  },
  props: {
    "PagesInitData": {
      required: true,
      default: ''
    },
  },
  data() {
    return {
      isShow: false,
      initData: '',
      title2:'专业健康知识'
    }
  },
  watch: {
    PagesInitData: {
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        let data = newValueA.Plate8
        data.map(v => {
          let substr2 = v.Title.substr(-2)
          if (substr2 == '因素') {
            this.isShow = true
            this.initData = v
            // console.log(v, 777777)
          }
        })
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.testReport_pages13 {
  width: 595px;
    height: 841px;
  background-color: #fff;
  overflow: hidden;
  .testReport_pages13_content {
    overflow: hidden;
    margin-top: 64px;
    margin-left: 113px;
    position: relative;
    width: 390px;
    height: 684px;

    .testReport_pages13_content_bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .testReport_pages13_content_text {
      margin-left: 93px;
      margin-top: 21px;
      font-size: 21px;
      font-weight: 500;
      color: #69B287;
    }

    .testReport_pages13_content_text1 {
      margin-left: 93px;
      margin-top: 45px;
      font-size: 12px;
      font-weight: 400;
      color: #474F57;
    }

    .testReport_pages13_content_ul {
      // margin-top: 66px;

      .testReport_pages13_content_li {
        margin-bottom: 25px;
        .testReport_pages13_content_li_title {
          margin-left: 81px;
          width: 289px;
          height: 25px;
          background: #69B287;
          border-radius: 13px;
          font-size: 14px;
          font-weight: 500;
          color: #FFFFFF;
          padding-left: 12px;
        }
      }

      .testReport_pages13_content_li_li {
        display: flex;
        align-items: center;
        // margin-top: 30px;

        .testReport_pages13_content_li_li_left {
          margin-right: 30px;
          margin-left: 51px;
          width: 11px;
          height: 11px;
          background: #FFFFFF;
          border: 1px solid #69B287;
          border-radius: 50%;
        }

        .testReport_pages13_content_li_li_text {
          font-size: 12px;
          font-weight: 400;
          color: #474F57;
          width: 300px;
        }
      }
    }
  }
}
</style>
